const CATEGORIES = [
  {
    category: 'guides',
    label   : 'User\'s Guide',
  },
  {
    category: 'enhancement',
    label   : 'Enhancement',
  },
  {
    category: 'extensions',
    label   : 'Extensions',
  },
  {
    category: 'components',
    label   : 'Components',
  },
];

// Use cjs exports for requiring this by gatsby-node.js
module.exports = CATEGORIES;